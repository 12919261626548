/*!
Theme Name: MPB
Theme URI: http://google.com/
Author: MPB
Author URI: http://google.com/
Description: Theme for the Partnership to Protect Workplace Opportunity
Version: 1.0
Text Domain: mpb
*/

@import "css/bootstrap-modal-patch.less";
@import "css/lib/bootstrap/bootstrap.less";

//* Bootstrap overrides
@screen-sm: 768px;
@screen-md: 1024px;

//* Media
@mobile440minus: ~"only screen and (max-width: 440px)";
@mobile: ~"only screen and (max-width: 768px)";
@tabletplus: ~"only screen and (min-width: 769px)";
@tablet: ~"only screen and (min-width: 769px) and (max-width: 1024px)";
@tabletminus: ~"only screen and (max-width: 1024px)";
@desktop: ~"only screen and (min-width: 1025px)";

@blue1: #102236;
@blue2: #89c1dd;
@blue3: #417f9d;
@blue4: #53a6d0;
@blue5: #d3eaef;
@blue6: #244667;
@grey1: #e3e6e9;
@grey2: #89939e;
@grey3: #cccbcb;
@grey4: #eceff2;
@red: #c82127;
@red2: #9e1b25;
@red3: #f8e1e7;
@white: #ffffff;
@black: #000000;

@icon-font-path:          "../../../fonts/";

@font-family-sans-serif:  "futura-pt", Helvetica, Arial, sans-serif;
@headings-font-family:    "futura-pt",sans-serif;
@headings-font-weight:    800;
@headings-line-height:    1.2;
@headings-color:          @blue1;

@import "css/page-resources.less";

// Remove XL breakpoint
@media screen and (min-width:760px) {
  .container {
	width: 970px !important;
  }
}

section {
	padding: 30px 30px;
	text-align: center;
}

header {
	position: relative;
	top:0;
	box-sizing: border-box;
	background: white;
	width:100%;
	z-index: 1000;
	border-bottom: 5px solid @blue2;
	.admin-bar & {
		&.fixedsticky-on {
			top:32px; //admin bar
			@media (max-width: 600px) {
				top:0;
				border-bottom: 5px solid @blue2;
			}
		}
	}


	.mpb-logo {
		text-align: center;
		background-image: url('img/logo.svg');
		background-size: 392px 100px;
		background-position: center;
		background-repeat: no-repeat;
		width: 392px;
		height: 100px;
		font-size: 0;
		margin: 20px auto;
		transition: height .4s, width .4s, background-size .4s, margin .4s;
		transition-delay: .1s;
		@media @mobile {
			background-size: 196px 50px;
			height: 50px;
			width: 196px;
			margin: 10px auto;
		}
	}

	.mpb-menu {
		font-size: 0;
		min-height: 0;
		min-width: 0;
		position: absolute;
		top: 0;
		right: 20px;
	}
	&.fixedsticky-off {
		.mpb-menu {
			.admin-bar & {
				top: 32px;
			}
		}
		border-bottom: 5px solid @blue2;
	}

	.mpb-menu-a:before {
		font-size: 48px;
		content: '\2630';
		color: #333;
	}

	@media (min-width: 992px) {
		.mpb-menu {
			display: none;
		}
	}

	@media (max-width: 991px) {
		.mpb-main-nav {
			display: none;
		}
		.mpb-logo {
			margin: 30px auto;
		}
		#responsive.modal {
			.admin-bar & {
				top: 32px;
			}
		}
	}

	.mpb-menu-a:hover {
		text-decoration: none;
	}

	.mpb-main-nav {
		box-sizing: border-box;
		font-size: 16px;
		background-color: @blue1;
		padding: 5px 0;
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			text-align: center;

			@media (max-width: 610px) {
				text-align: left;
			}

			li {
				display: inline-block;
				color: @white;
				padding: 5px 20px;
				@media (max-width: 810px) {
					padding: 5px 10px;
				}
				@media @mobile {
					font-size: 14px;
					font-weight: bold;
					padding: 5px 5px;
				}

			  a {
				color: @white;
			    text-decoration: none;
			    letter-spacing: 0.03em;
			  }
			  a:hover {
				color: @blue2;
			    text-decoration: none;
			  }
			}
		}
	}

	&.fixedsticky-on {
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
		.mpb-logo {
			background-size: 196px 50px;
			height: 50px;
			width: 196px;
			margin: 10px auto;

			@media (max-width: 991px) {
				margin: 15px auto;
			}
		}
	}
}

section.mpb-contact-us {
	background-image: url('img/flag.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: -100px;
	margin-top: 20px;
	@media @mobile {
		margin-bottom: -30px;
		margin-top: 30px;
	}
}

.mpb-mission-cta, .mpb-contact-us {
	background: rgba(16, 34, 54, 0.5);
	color: white;
}

.mpb-content-breakout > .row > div {
	@media (max-width: 992px) {
		padding-left: 0;
		padding-right: 0;
	}
}

.mpb-content-breakout.row {
}

.mpb-content-breakout .row:last-of-type {
}

@media (min-width: 992px) {
	.col-md-6.col-sm-12.mpb-content-pane.mpb-facts-articles {

	}

	.col-md-6.col-sm-12.mpb-content-pane.ppwa-take-action-now {
		top: -1px;
		left: -1px;
	}
}

.mpb-clifford {
	margin: 8px 0 10px 0;
}

.mpb-very-large-fact {
	font-size: 30px;
	padding: 20px 35px;
	background-color: @blue1;
	color: @white;
	text-align: center;
	margin-top: 25px;
	max-height: 354px;
	@media @mobile {
		clear: both;
		margin-bottom: 50px;
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	h3 {
		color: @white;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		font-size: 30px;
	}
	p {
		color: @white;
		font-size: 25px;
		padding: 25px 0;
	}
	.button {
		background: @red;
		color: @white;
		font-size: 20px;
		padding: 5px 0;
		text-transform: uppercase;
		letter-spacing: 0.03em;
		margin-bottom: 10px;
		&:hover {
			background: @red2;
		}
	}
}

p.mpb-mission-statement {
	line-height: 175%;
	max-width: 650px;
	margin: auto;
	padding: 50px 0;
	font-weight: 200;
	font-size: 16px;
}

p.mpb-take-action {
	line-height: 175%;
	margin: auto;
	padding: 16px 0;
	font-weight: 200;
	font-size: 16px;
}


@media (max-width: 992px) {

	.row {
		margin-left: 0;
		margin-right: 0;
	}

	footer nav ul li {
		width: 100%;
	}

	footer nav ul {
		padding-left: 0;
	}
}

blockquote h3:before {
	content: "\201C";
	font-weight: bold;
	font-size: 128px;
	color: #d7d7d7;
	position: absolute;
	top: 0.5em;
	left: -0.1em;
	font-family: 'Georgia';
	line-height: 1px;
}

blockquote h3:after {
	content: "\201D";
	font-weight: bold;
	font-size: 128px;
	color: #d7d7d7;
	position: absolute;
	bottom: 0.3em;
	right: -0.2em;
	font-family: 'Georgia';
	line-height: 1px;
}

/* TODO temporary modal style -- please revise */

@media (max-width: 991px) {
	header #responsive .mpb-main-nav {
		display: block;
	}
}

.modal-body {
	background-color: @blue1;
}

#responsive .mpb-logo {
	margin-top: 0;
	margin-bottom: 0;
}

#reponsive h4 {
	text-align: center;
}

#responsive .mpb-main-nav {
	background: none;
}

#responsive .mpb-main-nav ul {
	padding-left: 0;
}

#responsive .mpb-main-nav ul li {
	display: block;
	background-color: @blue1;
	color: @white;
	border-bottom: 1px solid @blue4;
	text-align: center;
	&:hover {
		background-color: @blue4;
		a {
			color: @white;
			display: block;
			height: auto;
			width: 100%;
		}
	}
}

#responsive h4 {
	text-align: center;
}

.modal-footer button {
	display: none;
}

#responsive ul, #responsive nav {
	padding: 0;
}

#responsive li {
	padding: 17px 0 12px 0;
}

#responsive li:first-of-type {
	border-top: 1px solid @blue4;
	margin-top: 10px;
}

#responsive li:last-of-type {
	margin-bottom: 10px;
}

@media (max-width: 350px) {
	div#responsive {
		width: 93%;
		padding-right: 0;
	}
}

/* end temporary modal style */

/* Newsroom */
.mpb-news-teaser-noimage.col-md-6.col-sm-12:nth-of-type(3) {
	clear: both;
}

.mpb-mission-header {
	letter-spacing: 0.08em;
	text-transform: uppercase;
	color: @white;
	font-weight: 700;
}

.testimonial {
	font-size: 13px;
	color: @grey2;
	line-height: 1.5em;
	font-family: 'Lato', Calibri, Arial, sans-serif;
}

.test-name {
	font-size: 13px;
	color: #6f6f6f;
	margin-top: -5px;
}

.test-head {
	padding-bottom: 15px;
	padding-left: 25px;
	border-left: 2px solid #e1e1e1;
	border-bottom: 2px solid #e1e1e1;
}

.test-group {
	margin-top: -10px;
}

.mpb-call-to-action {
	.button {
		width: 25%;
		min-width: 300px;
		margin-left: auto;
		margin-right: auto;
		border: 2px solid #ddd;
		/* border-radius: 4px; */
		font-size: 20px;
		padding: 10px;
		text-align: center;
		margin-bottom: 30px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		&:hover {
			background-color: @white;
			border: 2px solid @white;
			color: @blue1;
		}
		&:visited {
			width: 25%;
			min-width: 300px;
			margin-left: auto;
			margin-right: auto;
			border: 2px solid #ddd;
			/*border-radius: 8px;*/
			font-size: 24px;
			padding: 10px;
			text-align: center;
			margin-bottom: 30px;
			letter-spacing: 0.8px;
		}
	}
	a {
		color: #FFF;
		&:hover {
			text-decoration: none;
		}
	}
}

.mpb-call-to-action-take-action {
	max-width: 500px;
	margin:40px auto 0 auto;
	overflow: hidden;
	min-height: 88px;
	.button {
		max-width: 240px;
		border: 2px solid #ddd;
		border-radius: 3px;
		font-size: 24px;
		padding: 10px;
		text-align: center;
		letter-spacing: 0.8px;
		margin:0 auto 30px auto;
		float: left;
		&:hover {
			background-color: #fff;
			border: 2px solid #fff;
			color: @blue3;
		}
	}

	a {
		color: #FFF;
		&:hover {
			text-decoration: none;
		}
		&:last-child .button {
			float: right;
		}
	}

	@media @mobile {
		.button, a:last-child .button {
			float: none;
		}
	}
}

#contactbutton {
	margin-top: 50px;
}

.attributename {
	display: block;
	font-size: 13px;
	padding-top: 25px;
}

blockquote {
	border: none;
	font-family: Lato, sans-serif;
	position: relative;
	max-width: 500px;
	margin: 0 auto !important;
	padding-top: 50px;
	padding-left: 0.4em;
	h3 {
		font-size: 24px;
		margin-top: 15%;
		margin-bottom: 15%;
		padding-left: 9%;
		font-weight: 200;
		color: #666;
		line-height: 40px;
	}
}


.mpb-content-breakout>.row .mpb-featured-fact {
	padding: 0;
	margin-top: 20px;
}

.mpb-featured-fact {
	padding-left: 0;
	@media @mobile {
		padding-left: 15px;
		padding-right: 15px;
	}
}

#circlebuttons {
	max-width:600px;
	margin: 0 auto;

	@media @mobile {
		margin-top: 50px;
	}

	.mpb-circle-image {
		height: 98px;
		width: 98px;
		margin: 15px;
	}

	.mpb-learn-about-us, .mpb-take-action-now {
		text-align: center;
		margin-bottom: 30px;
		width:50%;
		float:left;
		a {
			color: #333;
			&:hover {
				text-decoration: none;
				color: @blue3;
				img {
					opacity: 0.8
				}
			}
		}
		@media @mobile {
			float:none;
			width:100%;
		}
	}
}

.mpb-mission-cta {
	background-image: url('img/sparksbluev3.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@media @mobile {
	}
}

.mpb-logo-footer {
	text-align: center;
	font-size: 0;
	@media @mobile {
		margin-top: -5px;
	}
	img {
		width: 70%;
		@media @mobile {
			width: 100%;
			padding: 25px 0;
		}
	}
}

#footer_disclaimer {
	background-color: #f7f7f7;
	padding: 45px 0 10px 0;
	p {
		margin-top: -10px;
		margin-bottom: -15px;
		color: #999;
		vertical-align: middle;
		font-size: 12px;
	}
}

.footer_main {
	background-color: #f7f7f7;
}

#footer-nav {
	ul {
		list-style-type: none;
		text-align: left;
		padding-left: 140px;
		@media @mobile {
			padding-left: 0;
			text-align: center;
			padding-top: 10px;
			padding-bottom: 10px;
		}
		a {
		  	color: @blue1;
			&:hover {
				text-decoration: none;
			  	color: @blue2;
			}
		}
	}
}

#mpb-colophon {
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	margin-top: 50px;
	padding-left: 140px;
	color: #777;
	@media @mobile {
		padding-left: 15px;
		text-align: center;
	}
}

.mpb-main-nav {
	a {
		&:hover {
			text-decoration: none;
		}
	}
}

.carousel-container {
	//width: 100% !important;
	//padding-left: 0;
	//padding-right: 0;
}

.carousel-row {
	padding-bottom: 50px;
	margin-top: 25px;
	@media @mobile {
		margin-top: 0;
	}
}

.caption-container {
	background-color: @blue4;
	max-height: 50px;
	padding-left: 0;
	padding-right: 0;
	max-width: 100%;
	@media @mobile {
		max-height: 53px;
	}
}

.carousel-caption {
	text-shadow: none;
	position: relative;
	left: 0;
	bottom: 28px;
	h3 {
		font-size: 20px;
		letter-spacing: 0.03em;
		@media @mobile {
			font-size: 14px;
		}
		.cta_title {
			font-weight: 700;
			text-transform: uppercase;
		}
		.cta_description {
			font-weight: 500;
			text-transform: uppercase;
			&:before {
				content: ' // ';
			}
		}
	}
	a {
		color: white;
		font-weight: 500;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
}

.carousel-indicators {
	position: absolute;
	left: 50%;
	z-index: 15;
	width: 60%;
	margin-left: -30%;
	padding-left: 0;
	list-style: none;
	text-align: center;
	bottom: -40px;
	li {
		display: inline-block;
		width: 8px;
		height: 8px;
		margin: 1px;
		text-indent: -999px;
		border: 1px solid @grey3;
		border-radius: 10px;
		cursor: pointer;
		background-color: #000 \9;
		background-color: rgba(0, 0, 0, 0);
	}
	.active {
		margin: 0;
		width: 10px;
		height: 10px;
		background-color: @grey3;
	}
}

.mpb-about-us {
	background-image: url('img/about-banner.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: #FFF;
}

.mpb-facts {
	background-image: url('img/facts-banner.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: #FFF;
}

.mpb-action {
	background-image: url('img/blueprintsblue2.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: #FFF;
	min-height: 700px;
	font-size: 22px;
	padding-top: 20px;
	letter-spacing: 0.8px;
	.shout {
		text-transform: uppercase;
		font-size: 32px;
		font-weight: bold;
		margin-top: -75px;
	}
	p {
		padding-top: 100px;
	}


}

.mpb-latest-news {
	.mpb-mission-header {
		margin: -30px -30px 0 -30px;
		padding: 50px 0 40px;

		background-image: url('img/news-banner.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		color: @white;
	}
	.read-more-link {
		&:hover {
			text-decoration: none;
			.mpb-news-teaser-noimage img {
				opacity: .8;
			}
		}
	}
	.latest-news-post {
		&:nth-child(3n + 1) {
    		clear: both;
		}
	}
}

.mpb-contact {
	background-image: url('img/stripe-bg.png');
	background-position: center;
	background-repeat: repeat;
}

.greyline {
	margin-top: 50px;
	@media @mobile {
		display: none;
	}
}

#mpb-talking-points {
	max-width: 750px;
	margin: auto;
	padding: 50px 0;
}

.accordion-group {
	padding: 15px 0;
	a {
		&:hover {
			text-decoration: none;
		}
		&:focus {
			text-decoration: none;
		}
	}
	clear: both;
	h3 {
		padding: 18px 0 10px 100px;
		@media @mobile {
			font-size: 18px;
			padding: 22px 0 10px 20px;
		}
	}
}

.accordion-body {
	clear: both;
}

.accordion-inner {
	padding-top: 10px;
	p {
		font-size: 16px;
		line-height: 24px;
		padding: 20px 15px 0 145px;
		@media @mobile {
			padding: 10px;
		}
	}
	ul {
		font-size: 16px;
		line-height: 24px;
		padding: 20px 15px 20px 145px;
		@media @mobile {
			padding: 10px;
		}
		li {
			margin-bottom: 10px;
		}
	}
	.list-two {
		font-size: 16px;
		line-height: 24px;
		padding: 20px 15px 20px 50px;
		@media @mobile {
			padding: 10px 10px 10px 35px;
		}
		li {
			margin-bottom: 10px;
		}
	}
	@media @mobile {
		padding: 10px 15px;
	}
	h4 {
		padding-left: 145px;
		font-weight: bold;
		font-size: 18px;
		@media @mobile {
			padding-left: 10px;
		}
	}
}

.mpb-circle-image-accordion {
	height: 69px;
	width: 69px;
	float: left;
	margin-right: 25px;
	margin-left: 50px;
	z-index: 1000;
	@media @mobile {
		margin-right: 15px;
		margin-left: 0;
	}
}

.mpb-page-container {
	max-width: 600px;
	margin: auto;
	@media @mobile {}
}

.mpb-page-container-wide {
  max-width: 740px;
  margin: auto;
  @media @mobile {}
}

.mpb-page-title {
	text-align: center;
	font-size: 24px;
	letter-spacing: 0.08em;
	padding-bottom: 25px;
	text-transform: uppercase;
	@media @mobile {
		font-size: 18px;
	}
}

.mpb-page-body {
	padding: 50px 0;
	font-size: 16px;
	line-height: 24px;
	h1 {
		@media @mobile {
			font-size: 24px;
			text-align: center;
		}
	}
	@media @mobile {
		padding: 30px 10px;
	}
	p {
		padding-bottom: 15px;
	}
	h4 {
		letter-spacing: 0.03em;
		border-bottom: 2px solid #53a6d0;
		padding-bottom: 5px;
		margin-top: 30px;
		font-weight: 700;
	}
}

.mpb-about-body {
	padding: 50px 0 30px 0;
	font-size: 16px;
	line-height: 24px;
	h1 {
		@media @mobile {
			font-size: 24px;
			text-align: center;
		}
	}
	@media @mobile {
		padding: 0 10px 30px 10px;
	}
	p {
		padding-bottom: 15px;
	}
	ul {
		padding-bottom: 15px;
		li {
			padding-bottom: 10px;
		}
	}
}

.about-button {
	background-color: @blue4;
	width: 200px;
	height: 200px;
	text-align: center;
	color: @white;
	float: left;
	margin-left: 15%;
	margin-bottom: 5%;
	@media @mobile {
		display: block;
		margin-left: 20%;
	}
	&:hover {
		background-color: @blue6;
		cursor: pointer;
	}
	img {
		max-height: 100px;
		margin-top: 40px;
		margin-bottom: 15px;
	}
}

.mpb-about-photo {
	img {
		width: 100%;
		max-width: 425px;
		margin-top: 80px;
		@media @mobile {
			margin-top: 30px;
			max-width: 330px;
		}
	}
}



.mpb-about-partners {
	padding-bottom: 50px;
	margin: 0 auto;
	@media @mobile {
		padding-left: 0;
		padding-right: 10px;
		margin-left: 0;
	}
	@media @tablet {
		margin-left: 0;
	}
	ul {
		list-style-type: none;
		li {
			padding: 8px 0;
		}
	}
}

.mpb-member-container {
	max-width: 900px;
	margin: auto;
}

.mpb-about-memberlist {
	h3 {
		text-align: center;
		font-weight: 700;
		padding: 30px 0;
		@media @mobile {
			font-size: 18px;
		}
	}
  div.memberlist {
	column-count: 3;
	column-gap: 18px;
	columns:3;
	  @media @mobile {
		  column-count: 1;
		  columns: 1;
	  }

	ul {
	  list-style: none;
	  margin:0;
	  padding:0;
	  > li {
		display: inline-block;
		width: 100%;
	    padding-bottom: 15px;
	    line-height: 16px;
	  }
	}
  }
}

#partner-list {

}

#partner-list-two {
	@media @mobile {
		margin-top: -60px;
	}
}

#partner-name {
	text-align: center;
	padding: 50px 0;
	margin-top: 0;
	@media @mobile {
		padding: 20px 0;
		font-size: 24px;
	}
}

.grey-head {
	color: @grey3;
	text-transform: uppercase;
	font-size: 22px;
	letter-spacing: 0.05em;
	font-weight: 700;
	padding-bottom: 5px;
}

.mpb-facts-articles {
	padding-right: 0;
	@media @mobile {
		padding-left: 15px;
		padding-right: 15px;
	}
	h4 {
		color: #777;
		font-weight: 200;
	}
	h3 {
		color: @blue1;
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		letter-spacing: 0.03em;
	}
	p {
		padding-top: 2px;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.01em;
		color: @grey2;
	}
	.read-more-link {
		margin-top: 5px;
		&:hover {
		}
	}
	.mpb-facts-article {
		margin-bottom: 30px;
		padding-left: 0;
		padding-right: 30px;
		@media @mobile {
			height: auto;
			margin-bottom: 50px;
		}
		.mpb-article-header {
			margin-top: 8px;
			margin-bottom: 2px;
			font-weight: 700;
		}
		.post-type {
			color: @red;
			font-size: 12px;
			letter-spacing: 0.03em;
			margin-right: 8px;
			font-weight: 700;
		}
		.post-date {
			color: @grey2;
			font-size: 12px;
			letter-spacing: 0.03em;
		}
		.post-source {
			color: @blue1;
			font-size: 14px;
			letter-spacing: 0.03em;
			font-style: italic;
		}
	}

	@media @tabletplus {
		.mpb-facts-article {
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
			}
		}
	}
	@media @mobile {
		.mpb-facts-article {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.mpb-news-teaser-noimage-surround {
	box-sizing: border-box;
	padding: 15px;
	@media (max-width: 992px) {
		padding: 15px 0px;
		min-height: unset;
		display: block;
	}
	a {
		&:hover {
			text-decoration: none;
			color: @blue3;
		}
	}
}

.mpb-news-teaser-noimage {
	background-color: #FFF;
	padding-left: 0;
	padding-right: 0;
	min-height:170px;
	border: 2px solid #fff;
	&:hover {
		border-color: @blue3;
	}

	@media (max-width: 992px) {
		min-height: unset;
	}
	.news-content {
		padding: 10px 20px;
		text-align: left;
	}
	img {
		width: 100%;
		padding: 0;
		max-height: 135px;
	}
	h4 {
		font-size: 20px;
	}
}

#maincontactsubhead {
	h2 {
		max-width: 700px;
		margin: auto;
		color: #FFF;
		font-size: 24px;
		line-height: 35px;
		font-weight: 200;
		padding-bottom: 70px;
		@media @mobile {
			font-size: 16px;
			line-height: 22px;
		}
	}
	.contact-us-form {
		max-width: 400px;
		margin: auto;

		.gform_fields {
			overflow: hidden;
			.gfield {
				&:nth-child(1) {
					width: ~"calc(50% - 10px)";
					float: left;
					margin-right: 10px;
				}
				&:nth-child(2) {
					width: ~"calc(50% - 10px)";
					float: right;
					margin-left: 10px;
				}
			}
			@media @mobile {
				.gfield:nth-child(1), .gfield:nth-child(2) {
					width: 100%;
					float: none;
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}
	.button {
		width: 25%;
		background-color: transparent;
		min-width: 300px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 35px;
		border: 2px solid #ddd;
		/*border-radius: 8px;*/
		font-size: 24px;
		padding: 10px;
		text-align: center;
		margin-bottom: 30px;
		letter-spacing: 0.8px;
		border-radius: 0;
		@media @mobile {
			margin-left: -25px;
		}
		&:hover {
			background-color: #fff;
			border: 2px solid #fff;
			color: @blue3;
		}
		&:visited {
			width: 25%;
			min-width: 300px;
			margin-left: auto;
			margin-right: auto;
			border: 2px solid #ddd;
			/*border-radius: 8px;*/
			font-size: 24px;
			padding: 10px;
			text-align: center;
			margin-bottom: 30px;
			letter-spacing: 0.8px;
		}
	}
	.gfield_label {
		color: #FFF;
		font-weight: 200;
	}
	.gform_confirmation_message {
		color: #FFF;
		font-size: 24px;
		line-height: 35px;
		font-weight: 200;
		padding-bottom: 70px;
	}
}

.control-label {
	font-weight: 200;
}

.mpb-generic-header {
	background-image: url('img/blue-news.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: #FFF;
}

.generic-content {
	text-align: left;
	max-width: 730px;
	margin: auto;
	p {
		padding: 10px 0;
		margin: 0;
	}
}

.more-info {
	text-align: center;
	font-size: 14px;
	color: #999;
	letter-spacing: 0.5px;
}

/* Added for partner logos */

.mpb-partner-logo {
	text-align: center;
	padding: 0 20px;
	@media @mobile {
		margin: 10px 0;
		padding: 0 15px 15px 15px;
		min-height: inherit;
	}
	@media @tablet {
		margin-top: 25px;
		margin-bottom: 25px;
	}
}

.mpb-partner-logo img {
	max-width: 100%;
	max-height: 100%;
	display: block;
	bottom: 0;
	position: absolute;
	@media @mobile {
		position: relative;
		bottom: auto;
	}
}

.mpb-partner-logo p {
	font-weight: 700;
	display: block;
	padding-top: 25px;
}

.mpb-partner-logo-imgframe {
	min-height: 200px;
	position: relative;
	@media @mobile {
		min-height: inherit;
	}
}

section#mpb-action {

	.mpb-action-inner {
		margin-bottom: 80px;
	}

	@media (max-width: 780px) {
		padding: 40px 15px;

		.mpb-action-inner {
			padding: 0;
		}
	}

	h1, h2, h3, h4, h5, h6, p {
		margin: 0 0 20px;
	}
	h1 {
		text-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	}
	h2 {
		color: @blue2;
		text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
		font-size: 23px;
		margin-top: 0;
		line-height: 1.5em;
	}
	p {
		padding: 0;
		font-size: 14px;
		line-height: 1.5em;
		font-weight: 300;
		text-align: left;
	}
	ul {
		font-size: 14px;
		line-height: 1.5em;
		font-weight: 300;
		text-align: left;
		li {
			margin-bottom: 15px;
		}
	}
	form {

		.gform_body,
		.gform_footer {

			@media (min-width: 561px) {
				margin: 0 15%;
			}
		}
		.gfield {
			margin-bottom: 10px;
			&.gfield_error .gfield_label {
				background-color: @red;
				color: #fff;
				padding: 5px;
				border-radius: 4px;
			}
			&:first-child {
				select {
					width: 115px;
				}
			}
		}
		.ginput_container input, .ginput_container select, .ginput_container textarea {
			margin-top: 2px;
		}
		label {
			display: block;
			text-align: left;
			font-weight: 300;
			margin-bottom: 0;
		}
		input,
		select {
			border: none;
		}
		input[type=submit] {
			font-size: 22px;
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
			background-color: @blue2;
			display: inline-block;
			padding: 10px 30px;
			margin-top: 20px;
			max-width: 100%;
			text-align: center;

			@media (max-width: 1199px) {
				font-size: 18px;
			}
			@media (max-width: 440px) {
				font-size: 16px;
			}
			@media (max-width: 400px) {
				font-size: 14px;
				padding: 10px;
			}
		}
		.has_first_name.no_middle_name.has_last_name.no_suffix,
		.has_street.has_city.has_state.has_zip {
			overflow: hidden;
		}
		.name_first,
		.address_state {
			width: ~"calc(50% - 10px)";
			float: left;
			margin-right: 10px;
		}
		.name_last,
		.address_zip {
			width: ~"calc(50% - 10px)";
			float: right;
			margin-left: 10px;
		}
		input[type=submit]:hover {
			color: white;
		}
		.gfield_description {
			font-size: 12px;
			text-align: left;
			color: #f4f8fe;
		  	text-shadow: 1px 1px 1px rgba(0, 0, 5, 0.35);
			@media @mobile {
				font-size: 10px;
			}
		}
	}
  form#gform_4 {
	.ginput_container textarea {
	  height: 970px;
	}
  }
	#progress-bar {

		> div {
			position: relative;
			border-radius: 4px;
			overflow: hidden;
		    text-align: center;


			@media @mobile {

			}
		}

		.progress-bar-inner {
			position: absolute;
			top: 5px;
			right: 0;
			width: 100%;
			height: 30px;
			border: 4px solid white;
			background-color: #D7D7D7;
			border-radius: 4px;

			div {
				height: 100%;
				background-color: #91C0F5;
			}
		}
		.progress-bar-text {
			position: relative;
			text-align: center;
			color: black;
			line-height: 40px;
			font-size: 16px;
		}
	}

	.mpb-action-form {
		position: relative;
	}
	#mpb-action-softedge {
		display: none;
	}
	#mpb-action-thanks {
		display: none;
	}
	#iframe {
		background-color: rgba(255, 255, 255, 0.7);
		padding: 20px;
		border-radius: 4px;
		box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
	}
}

body.take-action-step-2 section#mpb-action {
	#mpb-action-gravityform {
		display: none;
	}
	#mpb-action-softedge {
		display: block;
	}
	@media @mobile {
		.mpb-action-content {
			display: none;
		}
	}
}

#facts-sidebar {
	background-color: #EDF7FF;
	margin-top: 50px;
	margin-bottom: 50px;
	min-height: 500px;
	padding: 20px 40px 20px 30px;
	h3 {
		text-align: center;
		color: @blue3;
		padding-bottom: 25px;
		font-size: 24px;
		line-height: 1.5em;
		max-width: 200px;
		margin: auto;
	}
	ul {
		line-height: 1.5em;
		.fact-title {
			color: @blue3;
			font-size: 16px;
			letter-spacing: 0.8px;
		}
		li {
			margin-bottom: 15px;
		}
	}
	.fact-link {
		padding-top: 25px;
		font-size: 16px;
		line-height: 1.5em;
	}
}
.news-content h5 {
	color: @grey2;
	line-height: 17px;
}
.news-content h4 {
	line-height: 24px;
}

body.single-gravityview {
	section {
		padding: 0;
		text-align: left;
		float: none;
	}
	header, section.mpb-generic-header {
		display: none;
	}
	div.container {
		width: auto;
		float: none;
	}
	div.gv-list-view {
		border: none;
		margin: 0;
		float: none;
	}
	div.gv-list-view-content {
		padding: 0;
		float: none;
	}
	div.gv-list-view-content-description {
		@media print {
			page-break-after: always;
			float: none;
		}
		h4 {
			display: none;
		}
	}
	div.prefix, div.fullname {
		display: inline;
		p {
			display: inline;
		}
	}
}
@page {
	margin: 0.5cm;
}

#step1 {
  @media @mobile {
	padding: 20px 0 0 0 !important;
  }
  img {

  }
  p {
	text-align: center !important;
	padding-top: 15px !important;
	padding-right: 0;
	padding-left: 0;
  }
}

#step2 {
  @media @mobile {
	padding: 20px 0 0 0 !important;
  }
  img {
	opacity: 0.3;
  }
  p {
	text-align: center !important;
	padding-top: 15px !important;
	padding-right: 0;
	padding-left: 0;
	opacity: 0.3;
  }
}

img.aligncenter {
	display: block;
	margin: 0 auto;
}

.mpb-action-content {
  img {
	margin-bottom: 75px;
  }
}

.stepinfo {
  font-size: 12px !important;
  line-height: 15px;
}

body#page-id-256 {
	h2 {
		display: none;
	}
}

.tweet-rep-main {
	background: @grey1;
	padding:20px;
	margin:0;

	.tweet-rep-inner {
		width:620px;
		margin:0 auto;
		min-height: 400px;
		@media (max-width: 650px) {
			width:100%;
		}
	}

	button {
		box-shadow: none;
		background-color: @blue2;
		border: 1px #ffffff solid;
		color: #ffffff;
		text-transform: uppercase;
		letter-spacing: 0.03em;
		border-radius: 4px;
		height: 28px;
	}

	h1 {
		text-transform: uppercase;
		text-align: center;
		color: #fff;
		margin: 10px 0;
		letter-spacing: 0.05em;
		font-size: 28px;
		padding-top: 30px;
		font-weight: 600;
		@media (max-width: 450px) {
			font-size: 28px;
		}
	}
	.wordpressText {
		color: #fff;
	    line-height: 1.5em;
	    font-weight: 300;
	    text-align: center;
	    font-size: 16px;
	}
	.tweetRepContents {
		margin-top: 50px;
	}

	.formHolder {
		box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
		border-radius: 4px;
		background: #fff;
		padding:15px;
		margin:15px 0;
		min-height: 250px;
		margin-bottom: 50px;

		#zipForm {
			text-align: center;
			padding-bottom: 20px;
		}
		#magicForm {
			text-align: center;
		}

		h2 {
			font-size:18px;
			text-align: center;
			padding-bottom: 30px;
			color: @blue1;
		}

		h3 {
			font-size:14px;
			font-weight:bold;
		}

		.addressInput {
			width: 400px;
			@media (max-width: 550px) {
				width:auto;
			}
		}
	}

	.resultsHolder {
		color: #fff;

		h2 {
			font-size:18px;
		}

		h3 {
			font-size: 16px;
			margin: 10px 0 0 0;
			text-transform: uppercase;
		}

		.tweet_message {
			position: relative;
			color: @blue1;
			margin: 5px 0 20px 0;
			border: 1px solid @grey2;
			background: #eeeeee;
			box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
			border-radius: 4px;
			padding: 15px 105px 15px 15px;
			clear:both;
			min-height:100px;
			box-sizing: border-box;

			@media (max-width: 400px) {
				width:100%;
			}

			.tweetButtonHolder {
				position: absolute;
				top:15px;
				right:15px;
			}
		}
	}

	.startOverHolder {
		margin: 10px 0;
	}

	.loading {
		display:none;
		text-align: center;
		font-size: 16px;
		height: 400px;
		box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
		border-radius: 4px;
		background: #fff;
		padding:40px 0;
		margin:15px 0;
		.spinner {
			margin: 30px auto;
			display:block;
			background-image: url('img/loading25.gif');
			background-position: center;
			height: 50px;
			width:50px;
			background-repeat: no-repeat;
		}
	}
}

.fixedsticky {
	position: -webkit-sticky;
	position: sticky;
}
/* When position: sticky is supported but native behavior is ignored */
.fixedsticky-withoutfixedfixed .fixedsticky-off,
.fixed-supported .fixedsticky-off {
	position: static;
	border-bottom: 5px solid @blue2;
}
.fixedsticky-withoutfixedfixed .fixedsticky-on,
.fixed-supported .fixedsticky-on {
	position: fixed;
	border-bottom: 5px solid @blue2;
}
.fixedsticky-dummy {
	display: none;
}
.fixedsticky-on + .fixedsticky-dummy {
	display: block;
	border-bottom: 5px solid @blue2;
}

body .ssba.ssba-wrap {
	display: none !important;
}
.mpb-action {
	.ssba.ssba-wrap {
		display: block !important;
	}
}

#latest-news-filter {
	margin-top: 25px;
	margin-bottom: 10px;

	.mobile-dropdown {
		display: none;
	}

	.btn {
		margin: 5px 10px;
	    background-color: transparent;
	    color: @blue3;
	    border: 2px solid @blue3;
	    border-radius: 0;
	    text-transform: uppercase;
	    letter-spacing: 0.8px;
	    font-size: 14px;
	    font-weight: 600;
	    padding: 7px 10px;
	    box-shadow: none;
	    &.active, &:hover {
	    	background-color: @blue1;
    		color: #fff;
	    }
	    &:active, &:focus {
	    	outline: none;
	    	box-shadow: none;
	    }
	}

	@media @mobile {
		display: inline-block;
		padding: 0;
		margin-bottom: 0;
		.mobile-dropdown {
			display: inline-block;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 0;
		}
		.button-container {
			display: none;
			background-color: #fff;
			position: absolute;
			left: 0;
			top: 45px;
			z-index: 10;
			text-align: left;
			border: 2px solid @blue3;
			&.mobile-open {
				display: block;
			}
			.btn {
				margin: 0;
				display: block;
				border: none;
				width: 100%;
				text-align: left;
			}
		}
	}
}

.single {
	.mpb-generic-header .entry-title {
		font-size: 24px;
		margin-top: 10px;
		margin-bottom: 10px;
		letter-spacing: 0.03em;
	}
}

.mpb-content {
	padding-bottom: 100px;
	@media @mobile {
		margin-top: 40px;
		padding-bottom: 30px;
	}
}

.single-content {
	@media @mobile {
		margin-top: 0;
	}
	.container {
		@media @mobile {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.child_pages {
	list-style-type: none;
	padding-left: 0;
	li {
		max-width: 300px;
		float: left;
		padding: 0 20px;
		@media @mobile {
			max-width: 100%;
			padding: 0;
		}
		p {
			padding-bottom: 0;
			padding-top: 10px;
		}
	}
  	li:last-child:nth-child(odd) {
	  margin-left: 150px;
	  width: 100%;
	  @media @mobile {
		margin-left: 0;
	  }
	}
	h4 {
		border-bottom: 2px solid @blue1;
		padding-bottom: 5px;
		margin-top: 20px;
		letter-spacing: 0.03em;
		font-weight: 700;
	}
	a {
		color: @blue4;
		&:hover {
			color: @blue3;
			text-decoration: none;
		}
	}
}

.mpb-news {
	padding-right: 0;
	@media @mobile {
		padding-left: 15px;
		padding-right: 15px;
	}
	h4 {
		color: #777;
		font-weight: 200;
	}
	h3 {
		color: @blue1;
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		letter-spacing: 0.03em;
	}
	p {
		padding-top: 2px;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.01em;
		color: @grey2;
	}
	.read-more-link {
		margin-top: 5px;
		&:hover {
		}
	}
	.mpb-facts-article {
		margin-bottom: 30px;
		padding-left: 0;
		padding-right: 30px;
		@media @mobile {
			height: auto;
			margin-bottom: 50px;
		}
		.mpb-article-header {
			margin-top: 8px;
			margin-bottom: 2px;
			font-weight: 700;
		}
		.post-type {
			color: @red;
			font-size: 12px;
			letter-spacing: 0.03em;
			margin-right: 8px;
			font-weight: 700;
		  	display: block;
		}
		.post-date {
			color: @grey2;
			font-size: 12px;
			letter-spacing: 0.03em;
		  	display: block;
		}
		.post-source {
			color: @blue1;
			font-size: 14px;
			letter-spacing: 0.03em;
			font-style: italic;
		  	display: block;
		}
	}

	@media @tabletplus {
		.mpb-facts-article {
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
			}
		}
	}
	@media @mobile {
		.mpb-facts-article {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
/* Contact form styles */
#gform_1 {
	label {
		font-size: 12px;
		color: @blue4;
	}
	#input_1_1 {
		margin-bottom: 15px;
	}
	#input_1_1_3_container {
		label {
			font-size: 12px;
			color: @blue4;
		}
	}
	#input_1_1_6_container {
		label {
			font-size: 12px;
			color: @blue4;
		}
	}
	li {
		margin-bottom: 25px;
	}
	.gform_footer {
		text-align:center;
		#gform_submit_button_1 {
			background-color: @blue4;
			border: none;
			min-width: 200px;
			min-height: 40px;
			color: @white;
			text-transform: uppercase;
			letter-spacing: 0.03em;
			font-weight: 700;
			&:hover {
				background-color: @blue3;
			}
		}
	}

}

//* Ajax loader button styles
.ajax-load-btn {
  margin: 0 auto;
  display: block;
  color: white;
  background-color: @blue4;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  border-radius: 0;
  min-height: 40px;
  min-width: 200px;

  &.loading {
	text-indent: -300%;
	background-image: url(img/ajax-loader.gif);
	background-position: center;
	background-repeat: no-repeat;
  }
  &:hover, &:active {
	color: white;

  }
}

