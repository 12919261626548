body.page-template-page-resources {
  #resource_list {
	  h4 {
		  font-weight: 700;
		  letter-spacing: 0.03em;
		  padding-top: 25px;
		  text-align: center;
	  }
	  .list {
		  list-style-type: none;
		  padding-left: 0;
		  padding-top: 25px;
		  li {
			  padding-bottom: 30px;
			  font-size: 18px;
			  letter-spacing: 0.01em;
			  a {
				color: @blue1;
			    display: block;
			    margin-bottom: 10px;
				  &:hover {
					  color: @blue1;
				  }
			  }
		  }
	  }
	  #resource_filters {
		  padding: 10px 0 50px 0;
		  text-align: center;
		  .search {
			  min-width: 350px;
			  display: block;
			  margin: auto;
			  padding: 0 10px;
			  margin-bottom: 20px;
			  height: 30px;
			  color: @blue1;
			  letter-spacing: 0.03em;
			  &:focus {
				  outline-color: transparent;
				  outline-style: none;
			  }
			  @media @mobile {
				  max-width: 100%;
				  min-width: 50px;
			  }
		  }
		  #filter_type {
			  margin-right: 10px;
			  border-radius: 0 !important;
			  background-color: @grey4;
			  border: 1px solid @grey2;
			  color: @grey2;
			  height: 30px;
			  &:focus {
				  outline-color: transparent;
				  outline-style: none;
			  }
		  }
		  #filter_subject {
			  margin-left: 10px;
			  border-radius: 0 !important;
			  background-color: @blue5;
			  border: 1px solid @grey2;
			  color: @grey2;
			  height: 30px;
			  &:focus {
				  outline-color: transparent;
				  outline-style: none;
			  }
		  }
	  }
	  span.resource_types {

	  }
	span.resource_type {
	  padding: 0 4px;
	  background: @grey4;
	  font-size: 11px;
	  display: inline-block;
	  color: @grey2;
	  margin-right: 3px;
	  font-style: italic;
	}
	span.filetype {
	  padding: 0 4px;
	  background: @red3;
	  font-size: 11px;
	  display: inline-block;
	  color: @grey2;
	  text-transform: uppercase;
	  margin-right: 3px;
	  font-style: italic;
	}
	span.subject_label {
	  padding: 0 4px;
	  background: @blue5;
	  font-size: 11px;
	  color: @grey2;
	  display: inline-block;
	  margin-right: 3px;
	  font-style: italic;
	}
	span.subject {
	  display: none;
	}

	span.old_page {
	  display: none;
	}
  }
}